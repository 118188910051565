<template>
    <ValidationProvider
        tag="div"
        :rules="rules"
        :name="label || name"
        :vid="vid"
        :mode="mode"
        v-slot="{ classes, errors }"
    >
        <!-- vee-validate was grabbing the first element with a v-model
        to apply validation on.  I added a v-model to the b-dropdown
    to avoid aplying validation to the search input.-->
        <b-form-group :label-for="name || labelText" :label="labelText" class="keep-on-top">
            <b-row>
                <b-col class="">
                    <b-dropdown
                        ref="bDropDown"
                        variant="outline-primary"
                        v-model="selectedOption"
                        :name="name"
                        toggle-class='select-button custom-select'
                        :class="{
                            ...classes,
                            'validation-fail': errors.length > 0,
                            disabled: disabled,
                            'short-dropdown': shortDropdown
                        }"
                        @shown="focusSearchInput"
                        :disabled="disabled"
                        block
                    >
                        <template v-slot:button-content class="text-left">
                            <div class="text-left buttontext">
                                {{ buttonText }}
                                <br />
                            </div>
                        </template>
                        <b-dropdown-form>
                            <b-input
                                v-show="!hideSearch"
                                ref="searchInput"
                                type="search"
                                @input="debounceUserFilter"
                                placeholder="Search"
                                @keydown.down.prevent="focusResults"
                                @keydown.enter.prevent="filterEnter"
                            ></b-input>
                        </b-dropdown-form>
                        <div v-if="$slots.headerSlot" class="px-4"
                        >
                             <slot   name="headerSlot">
                            </slot>
                            <b-dropdown-divider></b-dropdown-divider>
                            </div>
                        <b-dropdown-item-button
                            v-if="
                                noOptionsMessage &&
                                    filteredOptions &&
                                    filteredOptions.length == 0
                            "
                            disabled
                        >
                            {{ noOptionsMessage }}
                        </b-dropdown-item-button>
                        <b-dropdown-item-button
                            v-for="(option, index) in userFilteredOptions"
                            :class="
                                selectedOption === option
                                    ? 'selected-option'
                                    : ''
                            "
                            :key="index"
                            :ref="`item${index}`"
                            :value="value"
                            @click="selectOption(option)"
                        >
                            <slot name="optionText" :option="option">{{ option[itemText] }}</slot>

                        </b-dropdown-item-button>
                    </b-dropdown>
                </b-col>
                <b-col v-if="$slots.append" cols="auto" class="ml-auto pl-0">
                    <slot name="append"> </slot>
                </b-col>
            </b-row>
            <slot name="error-message" v-if="errors && errors.length > 0">
            <!-- allow for easy way to set a custom error message -->
            <span>{{ errors[0] }}</span>
            </slot>
        </b-form-group>
    </ValidationProvider>
</template>

<script>
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';
import baseInputs from '@/components/mixins/BaseInputs';
import validatedInput from '@/components/mixins/ValidatedInput';
import { textIncludes } from '@/components/Common/Formatters.js';
import store from '@/store/store.js';
import debounce from 'lodash/debounce';

export default {
    name: 'p-advanced-select',
    mixins: [baseInputs, validatedInput],
    props: {
        value: [String, Number, Object],
        noSelectionMessage: {
            type: String,
            default: '\xa0'
        },
        dataType: String,
        options: Array,
        filter: [String, Number, Function],
        addEmptyOption: Boolean,
        addInvalidOption: {
            type: Object,
            default: null
        },
        emptyOnNullFilter: {
            type: Boolean,
            default: false
        },
        itemText: {
            type: String,
            default: 'text'
        },
        itemValue: {
            type: String,
            default: 'value'
        },
        emptyValueSpace: {
            type: Boolean,
            default: false
        },
        returnObject: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        shortDropdown: {
            type: Boolean,
            default: false
        },
        hideSearch: {
            type: Boolean,
            default: false
        },
        noOptionsMessage: {
            type: String,
            default: ''
        },
        defaultToFirst:{
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            innerOptions: [],
            selectedOption: null,
            userFilter: '',
            waitForInputDataTypes: ["customers"]
        };
    },
    async created() {
        if (this.dataType) {
            // can't do this in a computed property because b-form-select :options requires an Array, not a Promise.
            this.innerOptions = await this.loadList(this.dataType);
            this.unsubscribe = store.subscribe(mutation => {
                if (
                    this.dataType &&
                    mutation.type
                        .toLowerCase()
                        .includes(this.dataType.toLowerCase()) &&
                    mutation.payload.length > 0
                ) {
                    this.innerOptions = mutation.payload;
                    this.setInnerOptionsToObjects();
                }
            });
        } else {
            this.innerOptions = this.options;
        }
        this.setInnerOptionsToObjects();
        if(this.defaultToFirst && this.value === null && this.filteredOptions.length > 0){
            this.selectOption(this.filteredOptions[0]);
        }
    },
    beforeDestroy() {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    },
    computed: {
        buttonText() {
            if (!this.selectedOption) {
                return this.noSelectionMessage;
            }
            return this.selectedOption[this.itemText];
        },

        //options that match the base filter ( doesn't apply the user filter)
        //these are all valid options
        filteredOptions() {
            let filteredOptions = this.innerOptions;
            if (this.filter || this.emptyOnNullFilter) {
                if (typeof this.filter == 'function') {
                    filteredOptions = filteredOptions.filter(x =>
                        this.filter(x)
                    );
                } else {
                    filteredOptions = filteredOptions.filter(
                        x => x.filter == this.filter
                    );
                }
            }
            if(this.addInvalidOption){
                filteredOptions = [this.addInvalidOption, ...filteredOptions]
            }
            if (this.addEmptyOption) {
                let emptyOption = {};
                emptyOption[this.itemText] = '\xa0'; //space keeps it from colapsing height
                emptyOption[this.itemValue] = this.emptyValueSpace ? '\xa0' : null;
                return [emptyOption, ...filteredOptions];
            }
            return filteredOptions;
        },

        //options that match the base filter and then the user filter
        //these are the options displayed to the user to select.
        userFilteredOptions() {
            let filteredOptions = this.filteredOptions;

            if(this.waitForInputDataTypes.includes(this.dataType) && !this.userFilter)
            {
                return [];
            }

            if (this.userFilter != '') {
                filteredOptions = filteredOptions.filter(x =>
                    textIncludes(x[this.itemText], this.userFilter)
                );
            }
            
            return filteredOptions;
        }
    },
    watch: {
        options: {
            handler: function() {
                this.innerOptions = this.options;
                this.setInnerOptionsToObjects();
            }
        },
        filteredOptions: {
            handler: function() {
                let selectedOption = this.getSelectedOption();
                if (!selectedOption) {
                    //if value isn't a valid option, clear it.
                    this.handleInput(null);
                } else {
                    this.selectedOption = selectedOption;
                }
            }
        },
        value: {
            handler: function() {
                //catch any value changes made outside this component
                this.selectedOption = this.getSelectedOption();
            }
        },
        userFilter: {
            handler: function() {
                //When the drop down pops up and is filtered to a small result set, the remaining items hover
                //on an island without moving down to meet the input.  Triggering redraw to fix.
                //Not critical, but avoids the input becoming disconnected.
                var resizeEvent = window.document.createEvent('UIEvents');
                resizeEvent.initUIEvent('resize', true, false, window, 0);
                window.dispatchEvent(resizeEvent);
            }
        }
    },

    methods: {
        debounceUserFilter: debounce(function (value) {
            this.userFilter = value;
        }, 300),
        filterEnter() {
            if (this.userFilteredOptions.length == 1) {
                this.selectOption(this.userFilteredOptions[0]);
                this.$refs.bDropDown.hide(true);
            }
        },
        focusResults() {
            this.$refs.bDropDown.focusNext(new Event('keydown'));
        },
        getSelectedOption() {
            if (this.returnObject) {
                return this.value;
            }
            return this.filteredOptions.find(
                x => x[this.itemValue] == this.value
            );
        },
        selectOption(option) {
            this.selectedOption = option;
            this.$emit('selectedObject', option);

            if (this.returnObject) {
                this.handleInput(option);
                this.$emit('change');
                return;
            }
            this.handleInput(option[this.itemValue]);
            this.$emit('change');
        },
        async loadList(dataType) {
            return await selectListOptionsDataContext.getStoreDropdownData(
                dataType
            );
        },

        setInnerOptionsToObjects() {
            //to match bootstrap selects
            //If option array is a list of strings, it will be used for both the generated value and text fields.
            if (
                this.innerOptions.length > 0 &&
                typeof this.innerOptions[0] === 'string'
            ) {
                this.innerOptions = this.innerOptions.map(x => ({
                    text: x,
                    value: x
                }));
            }
        },
        focusSearchInput() {
            this.userFilter = '';
            this.$refs.searchInput.focus();
        }
    }
};

</script>
<style scoped lang="scss">
@import "@/styles/app/common/variables.scss";
/deep/ .selected-option {
    background: #23b7e5;
}
/deep/ .selected-option:hover {
    background: #23b7e5 !important;
}
/deep/ .disabled {
    background: #edf1f2;
}

/deep/ .short-dropdown .dropdown-menu {
    max-height: 200px;
}

/deep/ .dropdown-menu {
    max-height: 250px;
    overflow: auto;
}

/deep/ .dropdown-toggle {
    text-align: left;
}
/deep/ .dropdown-toggle::after {
    //using the triangles provided by custom-select to match other drop downs, so hiding this one
    display: none;
}

/deep/ .buttontext {
    white-space: nowrap;
    overflow: hidden;
}
/deep/ .btn.dropdown-toggle {
    border-radius: 0px;
}

/deep/ .validation-fail > .btn.dropdown-toggle {
    border-color: #f05050;
}

/deep/ button.btn-outline-primary.select-button {
    display: inline-block;
    width: 100%;
    height: 2.1875rem;
    padding: 0.375rem 2rem 0.375rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.52857;
    color: #495057;
    vertical-align: middle;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid $input-default-border-color;
    font-size: 1rem;
}
/deep/ button.btn-outline-primary.select-button:disabled {    
    border: 1px solid $input-border-color;
}
/deep/ .btn-outline-primary.select-button[aria-expanded="true"] {
    color: $btn-secondary-color;
    background-color: #f5f5f5;
}
/deep/ .keep-on-top {
    --dummy-var: 1;
}
</style>
