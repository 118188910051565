<template>
    <b-card class="w-100" no-body>
        <b-card-body class="pt-2">
            <b-row>
                <b-col>
                    <slot name="search-input" v-if="includeSearchInput && filters && filters.options">
                        <b-input-group>
                            <b-form-input
                                size="md"
                                ref="searchInput"
                                name="automatic"
                                type="search"
                                hide-label
                                :placeholder='searchMessageComputed'
                                v-model="filters.automatic"
                                :debounce-wait="300"
                                @keydown.enter.prevent=""
                            />
                            <p-select
                                class="ml-2"
                                v-model="filters.searchBy"
                                :options="filters.options"
                            />
                        </b-input-group>
                    </slot>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <slot name="above-table"> </slot>
                </b-col>
            </b-row>
            <b-row>
                <b-table
                    ref="searchResults"
                    v-bind="$attrs"
                    responsive
                    striped
                    hover
                    small
                    show-empty
                    sort-icon-left
                    no-sort-reset
                    :selectable="selectable"
                    :select-mode="selectMode"
                    :empty-text="computedEmptyText"
                    :api-url="searchUrl"
                    :items="itemProvider"
                    :fields="calculatedFields"
                    :current-page="currentPage"
                    :per-page="perPageSelection"
                    :sort-by="sortBy"
                    :sort-desc="sortDesc"
                    @row-clicked="
                        e =>
                            enableRowSelection &&
                            !disableRowCheck(e) &&
                            rowClicked
                    "
                    @sort-changed="sortChanged"
                    @row-selected="$emit('row-selected', $event)"
                    @context-changed="updateSelectAll"
                    @refreshed="updateSelectAll"
                    @input="itemsChanged"
                    class="mr-md-3 ml-md-3"
                >
                    <template #thead-top v-if="showTopPaging">
                        <b-tr>
                            <b-th
                                :colspan="calculatedFields.length"
                                class="pr-0"
                            >
                                <b-col class="d-flex pb-0 pt-0 pr-0">
                                    <div class="ml-auto mr-3">
                                        <p-select
                                            v-model="perPageSelection"
                                            :options="perPageOptions"
                                            v-if="
                                                showPerPageSelector &&
                                                    totalRows > minPerPage
                                            "
                                        >
                                        </p-select>
                                    </div>
                                    <b-pagination
                                        v-model="currentPage"
                                        :total-rows="totalRows"
                                        label="Per Page"
                                        :per-page="perPageSelection"
                                    ></b-pagination>
                                </b-col>
                            </b-th>
                        </b-tr>
                    </template>
                    <template v-slot:table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                    </template>
                    <template
                        v-for="(_, name) in $scopedSlots"
                        :slot="name"
                        slot-scope="slotData"
                    >
                        <slot :name="name" v-bind="slotData" />
                    </template>
                    <template v-slot:head(selected)="data">
                        <p-checkbox
                            v-if="!singleSelect"
                            :value="allSelected"
                            @change="toggleSelectAll"
                            :label="selectionLabel"
                        ></p-checkbox>
                        <span v-else>{{ singleSelectButtonLabel }}</span>
                    </template>
                    <template v-slot:cell(selected)="{ value, item }">
                        <p-checkbox
                            v-if="!singleSelect"
                            :value="isSelected(item)"
                            @change="onSelection(item)"
                            :disabled="disableRowCheck(item)"
                        ></p-checkbox>
                        <p-button
                            v-else
                            size="sm"
                            @click="onAddSelection(item)"
                            >{{ singleSelectButtonLabel }}</p-button
                        >
                    </template>
                    <template v-slot:cell(dualSelected)="{ value, item }">                    
                        <p-button size="sm" variant="outline-primary" @click="onAddSelection(item)" :disabled="disableSingleSelectButton">{{
                            singleSelectButtonLabel
                        }}</p-button>
                    </template>
                    <!-- putting the bottom pagination in the caption slot allows
                         it to be included in the table's busy spinner overlay -->
                    <template #table-caption>
                        <b-row class="mt-3">
                            <b-col class="d-flex py-0 pr-0 ">
                                <div class="mt-2">{{ totalRows }} Results</div>
                                <div class="ml-auto mr-3">
                                    <p-select
                                        v-model="perPageSelection"
                                        v-if="showPerPageSelector && totalRows > minPerPage"
                                        :options="perPageOptions"
                                    />
                                </div>
                                <b-pagination
                                v-if="showBottomPaging"
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    label="Per Page"
                                    :per-page="perPageSelection"
                                ></b-pagination>
                            </b-col>
                        </b-row>
                    </template>
                </b-table>
            </b-row>


        </b-card-body>
    </b-card>
</template>

<script>
import axios from 'axios';
import { searchPlaceHolderFormatter } from './Common/Formatters';

export default {
    name: 'p-search-table',
    inheritAttrs: false,
    props: {
        fields: Array,
        perPage: {
            type: Number,
            default: 10
        },
        perPageOptions: {
            type: Array,
            default: () => [
                { value: 10, text: 'Show 10' },
                { value: 25, text: 'Show 25' },
                { value: 50, text: 'Show 50' },
                { value: 100, text: 'Show 100' },
                { value: 500, text: 'Show 500' }
            ]
        },
        includeSearchInput: {
            type: Boolean,
            default: true
        },
        showPerPageSelector: {
            type: Boolean,
            default: true
        },
        //can probably remove showTopPaging prop when we add PerPageSelector to edit pages.
        //Added to keep existing behavior for now, and I don't think the top paging is needed
        //when they are stuck at 10 per page because the top and bottom paging controls are both always visible.
        showTopPaging: {
            type: Boolean,
            default: true
        },
         showBottomPaging: {
            type: Boolean,
            default: true
        },
        selectable: {
            type: Boolean,
            default: false
        },
        selectMode:{
            type: String,
            default: 'range'
        },
        sortBy: String,
        emptyText: String,
        sortDesc: Boolean,
        filters: Object,
        apiDestination: String,
        searchDestination: {
            type: String,
            default: null
        },
        submitSearchAsPost: {
            type: Boolean,
            default: false
        },
        singleSelect: {
            type: Boolean,
            default: false
        },
        singleSelectButtonLabel: {
            type: String,
            default: 'Add'
        },
        disableRowCheck: {
            type: Function,
            default: () => false
        },
        enableRowSelection: {
            type: Boolean,
            default: true
        },
        enableDualSelection: {
            type: Boolean,
            default: false
        },
        // todo: when we get rid of bootstrap select we should get rid of this property
        useBootstrapSelection: {
            type: Boolean,
            default: false
        },
        disableInitialSearch: {
            type: Boolean,
            default: false
        },
        editKey: {
            type: String,
            default: null
        }        
    },
    data() {
        return {
            currentPage: 1,
            totalRows: 0,
            tableSortBy: this.sortBy,
            tableSortDesc: this.sortDesc,
            allSelected: false,
            selectedRows: [],
            perPageSelection: 10,
        };
    },
    created() {
        this.perPageSelection = this.perPage;
    },
    watch: {
        filters: {
            handler: function() {
                this.refreshSearchResults();
            },
            deep: true
        },
        searchUrl: {
            handler: function() {
                this.goToFirstPage();
            }
        },
        perPageSelection: {
            handler: function(newValue) {
                this.$emit('per-page-change', newValue);
            }
        },
    },
    computed: {
        searchMessageComputed() {            
            return searchPlaceHolderFormatter(this.filters.options,this.filters.searchBy);
        },
        minPerPage() {
            return this.perPageOptions[0].value;
        },
        searchUrl() {
            var destination = '/search';
            if (this.searchDestination) {
                destination = '/' + this.searchDestination;
            }
            return this.apiDestination + destination;
        },
        selectionLabel() {
            return this.selectedRows.length > 0
                ? `(${this.selectedRows.length})`
                : '';
        },
        visibleFields() {
            return this.fields.filter(x => !x.hidden);
        },
        computedEmptyText(){
            if(this.filters?.automatic == "" && this.disableInitialSearch){
                return "Enter filtering criteria to see matching results";
            }
            return this.emptyText;
        },
        calculatedFields() {
            if (this.enableRowSelection && !this.useBootstrapSelection) {

                if(this.enableDualSelection){                    
                    return [
                        {
                            label: '',
                            key: 'selected',
                            sortable: false
                        },
                        ...this.visibleFields,
                        {
                            label: 'Quick Add',
                            key: 'dualSelected',
                            sortable: false
                        }
                    ];
                }else{
                    return [
                        {
                            label: '',
                            key: 'selected',
                            sortable: false
                        },
                        ...this.visibleFields
                    ];
                }
            }

            return this.visibleFields;
        },
        disableSingleSelectButton(){
            return this.selectedRows.length>0;
        }
    },
    methods: {
        sortChanged: function(ctx) {
            this.tableSortBy = ctx.sortBy;
            this.tableSortDesc = ctx.sortDesc;
            this.$emit('sort-by-change', ctx.sortBy);
            this.$emit('sort-desc-change', ctx.sortDesc);
            this.goToFirstPage();
        },
        goToFirstPage() {
            this.currentPage = 1;
        },
        rowClicked(element) {
            this.$emit('row-clicked', element);
        },
        refreshSearchResults() {
            this.$refs.searchResults.refresh();
            this.goToFirstPage();
            this.$emit('filtered');
        },
        getQueryParameters: function() {
            const pagination = {
                currentPage: this.currentPage,
                perPage: this.perPageSelection,
                sortBy: this.tableSortBy,
                sortDesc: this.tableSortDesc
            };

            return { ...pagination, ...this.filters };
        },
        itemProvider: function(ctx) {
            this.$emit('is-table-loading', true);
            if(this.filters?.automatic == "" && this.disableInitialSearch){
                        this.totalRows = 0;
                        this.$emit('total-rows-changed', 0);
                        this.$emit('is-table-loading', false);
                        return [];
            }else{
                if (this.submitSearchAsPost) {
                    //search filters that grow to large (list of excluded skus for example)
                    //we need to post them and get it frombody
                    return axios
                        .post(ctx.apiUrl, {
                            ...this.getQueryParameters()
                        })
                        .then(resp => {
                            this.totalRows = resp.data.count;
                            const items = resp.data.data;
                            this.$emit('is-table-loading', false);
                            return items || [];
                        });
                }

                return axios
                    .get(ctx.apiUrl, {
                        params: this.getQueryParameters()
                    })
                    .then(resp => {
                        this.totalRows = resp.data.count;
                        this.$emit('total-rows-changed', resp.data.count);
                        const items = resp.data.data;
                        this.$emit('is-table-loading', false);
                        return items || [];
                    });
            }
        },
        updateSelectAll() {
            this.allSelected = this.$refs.searchResults.computedItems
                .filter(x => !this.disableRowCheck?.(x))
                .every(elem => this.isSelected(elem));
        },
        toggleSelectAll() {
            if (!this.allSelected) {
                this.$refs.searchResults.computedItems
                    .filter(x => !this.disableRowCheck?.(x))
                    .forEach(elem => {
                        if (
                            !this.selectedRows.some(
                                x => JSON.stringify(x) === JSON.stringify(elem)
                            )
                        ) {
                            this.selectedRows.push({ ...elem });
                        }
                    });
            } else {
                this.$refs.searchResults.computedItems
                    .filter(x => !this.disableRowCheck?.(x))
                    .forEach(elem => {
                        this.selectedRows = this.selectedRows.filter(
                            x => JSON.stringify(x) !== JSON.stringify(elem)
                        );
                    });
            }
            this.$emit('input', this.selectedRows);
            this.updateSelectAll();
        },
        isSelected(item) {
            return this.selectedRows.some(
                x => JSON.stringify(x) === JSON.stringify(item)
            );
        },
        onSelection(item) {
            var existingIndex = this.selectedRows.findIndex(
                x => JSON.stringify(x) === JSON.stringify(item)
            );
            if (existingIndex > -1) {
                this.selectedRows.splice(existingIndex, 1);
            } else {
                this.selectedRows.push(item);
            }
            this.$emit('input', this.selectedRows);
            this.updateSelectAll();
        },
        onAddSelection(item) {
            this.onSelection(item);
            this.$emit('submit-selection');
        },
        itemsChanged(item) {
            let selectedCount = this.selectedRows.length;
            this.selectedRows = this.selectedRows.filter(s => item.findIndex(t => t[this.editKey] == s[this.editKey]) !== -1);
            this.updateSelectAll();
            if (selectedCount != this.selectedRows.length){
                this.$emit('selected-rows-changed', this.selectedRows);
            }
        }
    }
};
</script>


<style scoped lang="scss">
@import "@/styles/app/common/variables.scss";
/deep/ .right-align {
    text-align: right;
}

/deep/ th {
    user-select: none;
}

/deep/ caption{
    width: 98%; //avoids horizontal scrollbar
    color: $body-color;
}

/deep/ .showEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 1.7;
}
</style>